@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .departmentCard {
        min-height: 200; 
        max-height: 200;
        overflow: 'auto';
    }

    .dotPoints {
        @apply text-[17px] leading-[25px] list-disc ml-[32px];
    }

    .patchNoteHeadings {
        @apply text-[25px] font-bold;
    }

    .patchNoteSubHeading {
        @apply text-[17px] mb-[3px] font-semibold;
    }

    .patchDate {
        @apply mb-[5px] text-[15px];
    }
    
    .patchNoteHeaderBox {
        @apply flex justify-between items-center mt-[20px] sm:mt-[50px];
    }
}